<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{durumText}}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="RANDEVU" entityName="appointment"></EntityHeader>
					
					<div class="grid">
						<div class="col-4">
							<div class="field p-fluid">
								<EntityLookup id="ilgi" v-model="mainData.ilgiName" ref="entity_ilgi"
									label="İlgi" entityName="account" nameField="name" :state="true" :required="true" :disabled="isDisabled"
									@itemSelected="ilgiSelected = $event" @itemCleared="ilgiSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="grupfirmasi">Grup Firması <span style="color:red">*</span></label>
								<Dropdown id="grupfirmasi" v-model="mainData.grupFirmasi" :options="SM_grupFirmasi" optionLabel="Value" optionValue="AttributeValue" placeholder="Grup Firması" :showClear="true" :disabled="isDisabled" />
								<span v-if="v$.mainData.grupFirmasi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.grupFirmasi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="baslangic">Başlangıç Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="baslangic" v-model="mainData.baslangicSaati" autocomplete="off" :showIcon="true" :showTime="true" hourFormat="24" :disabled="isDisabled"/>
								<span v-if="v$.mainData.baslangicSaati.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.baslangicSaati.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						
						<div class="col-2">
							<div class="field p-fluid">
								<label for="sure">Süre</label>
								<Dropdown id="sure" v-model="mainData.sure" :options="sureList" optionLabel="label" optionValue="attributeValue" placeholder="Süre" @change="changeSure" :filter="true" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="bitis">Bitiş Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="bitis" v-model="mainData.bitisSaati" autocomplete="off" :showIcon="true" :showTime="true" hourFormat="24" :disabled="isDisabled" />
								<span v-if="v$.mainData.bitisSaati.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bitisSaati.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="konu">Konu <span style="color:red">*</span></label>
								<InputText id="konu" type="text" v-model="mainData.konu" :disabled="isDisabled" />
								<span v-if="v$.mainData.konu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.konu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						
						
						<div class="col-12">
							<div class="field p-fluid">
								<label for="aciklama">Açıklama <span style="color:red">*</span></label>
								<Textarea id="aciklama" rows="4" :autoResize="false" v-model="mainData.aciklama" :disabled="isDisabled" />
								<span v-if="v$.mainData.aciklama.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.aciklama.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						
						<div class="col-4">
							<div class="field p-fluid">
								<label for="tezgah">Tezgah <span style="color:red">*</span></label>
								<Dropdown id="tezgah" v-model="mainData.tezgah" :options="SM_tezgah" optionLabel="Value" optionValue="AttributeValue" placeholder="Tezgah" :showClear="true" :filter="true" :disabled="isDisabled" />
								<span v-if="v$.mainData.tezgah.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.tezgah.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="randevuYeri">Randevu Yeri</label>
								<Dropdown id="randevuYeri" v-model="mainData.randevuYeri" :options="SM_randevuYeri" optionLabel="Value" optionValue="AttributeValue" placeholder="Randevu Yeri" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<EntityLookupPopup id="satisprojesi" v-model="mainData.satisProjesiName" ref="entity_satisProjesi" label="Satış Projesi"
									entityName="opportunity" nameField="name" :disabled="isDisabled" :state="true"
									@itemSelected="satisProjesiSelected = $event" @itemCleared="satisProjesiSelected = null"
									parentFieldName="customerid" :parentId="ilgiSelected"
									:CrmDataTable_options="satisProjesi_CrmDataTable_options">
								</EntityLookupPopup>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<div class="grid">
						<div class="col">
							<div class="field p-fluid">
								<EntityLookup id="musteriKatilimci1" v-model="mainData.musteriKatilimci1Name" ref="entity_musteriKatilimci1"
									label="Müşteri Katılımcı 1" entityName="contact" nameField="fullname" :state="true" :required="true" :disabled="isDisabled"
									secondNameField="bm_unvan" secondNameFieldType="picklist" parentFieldName="parentcustomerid" :parentId="ilgiSelected"
									@itemSelected="musteriKatilimci1Selected = $event" @itemCleared="musteriKatilimci1Selected = null">
								</EntityLookup>
								<span v-if="v$.mainData.musteriKatilimci1Name.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.musteriKatilimci1Name.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<EntityLookup id="musteriKatilimci2" v-model="mainData.musteriKatilimci2Name" ref="entity_musteriKatilimci2"
									label="Müşteri Katılımcı 2" entityName="contact" nameField="fullname" :state="true" :disabled="isDisabled"
									secondNameField="bm_unvan" secondNameFieldType="picklist" parentFieldName="parentcustomerid" :parentId="ilgiSelected"
									@itemSelected="musteriKatilimci2Selected = $event" @itemCleared="musteriKatilimci2Selected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<EntityLookup id="musteriKatilimci3" v-model="mainData.musteriKatilimci3Name" ref="entity_musteriKatilimci3"
									label="Müşteri Katılımcı 3" entityName="contact" nameField="fullname" :state="true" :disabled="isDisabled"
									secondNameField="bm_unvan" secondNameFieldType="picklist" parentFieldName="parentcustomerid" :parentId="ilgiSelected"
									@itemSelected="musteriKatilimci3Selected = $event" @itemCleared="musteriKatilimci3Selected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<EntityLookup id="musteriKatilimci4" v-model="mainData.musteriKatilimci4Name" ref="entity_musteriKatilimci4"
									label="Müşteri Katılımcı 4" entityName="contact" nameField="fullname" :state="true" :disabled="isDisabled"
									secondNameField="bm_unvan" secondNameFieldType="picklist" parentFieldName="parentcustomerid" :parentId="ilgiSelected"
									@itemSelected="musteriKatilimci4Selected = $event" @itemCleared="musteriKatilimci4Selected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<EntityLookup id="musteriKatilimci5" v-model="mainData.musteriKatilimci5Name" ref="entity_musteriKatilimci5"
									label="Müşteri Katılımcı 5" entityName="contact" nameField="fullname" :state="true" :disabled="isDisabled"
									secondNameField="bm_unvan" secondNameFieldType="picklist" parentFieldName="parentcustomerid" :parentId="ilgiSelected"
									@itemSelected="musteriKatilimci5Selected = $event" @itemCleared="musteriKatilimci5Selected = null">
								</EntityLookup>
							</div>
						</div>
						
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<div class="grid">
						<div class="col">
							<div class="field p-fluid">
								<EntityLookup id="katilimci1" v-model="mainData.katilimci1Name" ref="entity_katilimci1"
									label="Form Katılımcı 1" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :required="true" :disabled="isDisabled"
									@itemSelected="katilimci1Selected = $event" @itemCleared="katilimci1Selected = null">
								</EntityLookup>
								<span v-if="v$.mainData.katilimci1Name.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.katilimci1Name.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<EntityLookup id="katilimci2" v-model="mainData.katilimci2Name" ref="entity_katilimci2"
									label="Form Katılımcı 2" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :disabled="isDisabled"
									@itemSelected="katilimci2Selected = $event" @itemCleared="katilimci2Selected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<EntityLookup id="katilimci3" v-model="mainData.katilimci3Name" ref="entity_katilimci3"
									label="Form Katılımcı 3" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :disabled="isDisabled"
									@itemSelected="katilimci3Selected = $event" @itemCleared="katilimci3Selected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<EntityLookup id="katilimci4" v-model="mainData.katilimci4Name" ref="entity_katilimci4"
									label="Form Katılımcı 4" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :disabled="isDisabled"
									@itemSelected="katilimci4Selected = $event" @itemCleared="katilimci4Selected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<EntityLookup id="katilimci5" v-model="mainData.katilimci5Name" ref="entity_katilimci5"
									label="Form Katılımcı 5" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :disabled="isDisabled"
									@itemSelected="katilimci5Selected = $event" @itemCleared="katilimci5Selected = null">
								</EntityLookup>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 4201,
			mainData: {},
			sureList: [
                { label: "30 dakika", attributeValue: 30 },
                { label: "1 Saat", attributeValue: 60 },
				{ label: "1,5 Saat", attributeValue: 90 },
				{ label: "2 Saat", attributeValue: 120 },
				{ label: "3 Saat", attributeValue: 180 },
				{ label: "4 Saat", attributeValue: 240 },
            ],
			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					visible: () => this.isKaydetVisible(),
					command: () => { this.OnSave(false); },
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					visible: () => this.isKaydetVisible(),
					command: () => { this.OnSave(true); },
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
					label:'Tamamlandı Olarak İşaretle',
					icon:'pi pi-fw pi-check-circle',
					visible: () => this.isTamamlandiOlarakIsaretleVisible(),
					command: () => { this.OnTamamlandiOlarakIsaretle(); },
				},
				{
					label: 'Yeniden ETKİNLEŞTİR',
					icon: 'pi pi-fw pi-history',
					visible: () => this.isDisabled,
					command: () => { this.RandevuyuYenidenEtkinlestir(); },
                },
			],
			satisProjesi_CrmDataTable_options: {
				searchAttributes: ["name", "customeridname"],
				filterParent: [
					{ 'name': 'customerid', 'filterOperator': 'eq', 'value': '34' },
					{ 'name': 'statecode', 'filterOperator': 'eq', 'value': '0' },
				],
            },
		}
	},
	async created() {

		this.crmService = new CrmService();
		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		this.OnLoad();
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		opportunityId() {
			debugger;
			return this.$route.params.opportunityid;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDisabled: function() {
			if (this.mainData.durum == 1) {
				return true;
			}

			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Randevular');
					if (filtered.length > 0) {
						return filtered[0].isWrite == false;
					}
				}
			}

			return true;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Randevular');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		durumText(){
			return this.mainData.durum == 0 ? "Durum: Açık" : "Durum: Tamamlanmış";
		},
		SM_tezgah: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_tezgah');
		},
		SM_randevuYeri: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_randevuyeri');
		},
		SM_grupFirmasi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_grupfirmasi');
		},
		ilgiSelected: {
			get: function () {
				if (this.mainData["ilgiId"]) {
					return { "Value": this.mainData["ilgiId"], "Name": this.mainData["ilgiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["ilgiId"] = null;
					this.mainData["ilgiName"] = null;
				}
				else {
					this.mainData["ilgiId"] = newValue.Value;
					this.mainData["ilgiName"] = newValue.Name;
				}
			}
		},
		musteriKatilimci1Selected: {
			get: function () {
				if (this.mainData["musteriKatilimci1Id"]) {
					return { "Value": this.mainData["musteriKatilimci1Id"], "Name": this.mainData["musteriKatilimci1Name"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["musteriKatilimci1Id"] = null;
					this.mainData["musteriKatilimci1Name"] = null;
				}
				else {
					this.mainData["musteriKatilimci1Id"] = newValue.Value;
					this.mainData["musteriKatilimci1Name"] = newValue.Name;
				}
			}
		},
		musteriKatilimci2Selected: {
			get: function () {
				if (this.mainData["musteriKatilimci2Id"]) {
					return { "Value": this.mainData["musteriKatilimci2Id"], "Name": this.mainData["musteriKatilimci2Name"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["musteriKatilimci2Id"] = null;
					this.mainData["musteriKatilimci2Name"] = null;
				}
				else {
					this.mainData["musteriKatilimci2Id"] = newValue.Value;
					this.mainData["musteriKatilimci2Name"] = newValue.Name;
				}
			}
		},
		musteriKatilimci3Selected: {
			get: function () {
				if (this.mainData["musteriKatilimci3Id"]) {
					return { "Value": this.mainData["musteriKatilimci3Id"], "Name": this.mainData["musteriKatilimci3Name"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["musteriKatilimci3Id"] = null;
					this.mainData["musteriKatilimci3Name"] = null;
				}
				else {
					this.mainData["musteriKatilimci3Id"] = newValue.Value;
					this.mainData["musteriKatilimci3Name"] = newValue.Name;
				}
			}
		},
		musteriKatilimci4Selected: {
			get: function () {
				if (this.mainData["musteriKatilimci4Id"]) {
					return { "Value": this.mainData["musteriKatilimci4Id"], "Name": this.mainData["musteriKatilimci4Name"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["musteriKatilimci4Id"] = null;
					this.mainData["musteriKatilimci4Name"] = null;
				}
				else {
					this.mainData["musteriKatilimci4Id"] = newValue.Value;
					this.mainData["musteriKatilimci4Name"] = newValue.Name;
				}
			}
		},
		musteriKatilimci5Selected: {
			get: function () {
				if (this.mainData["musteriKatilimci5Id"]) {
					return { "Value": this.mainData["musteriKatilimci5Id"], "Name": this.mainData["musteriKatilimci5Name"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["musteriKatilimci5Id"] = null;
					this.mainData["musteriKatilimci5Name"] = null;
				}
				else {
					this.mainData["musteriKatilimci5Id"] = newValue.Value;
					this.mainData["musteriKatilimci5Name"] = newValue.Name;
				}
			}
		},
		katilimci1Selected: {
			get: function () {
				if (this.mainData["katilimci1Id"]) {
					return { "Value": this.mainData["katilimci1Id"], "Name": this.mainData["katilimci1Name"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["katilimci1Id"] = null;
					this.mainData["katilimci1Name"] = null;
				}
				else {
					this.mainData["katilimci1Id"] = newValue.Value;
					this.mainData["katilimci1Name"] = newValue.Name;
				}
			}
		},
		katilimci2Selected: {
			get: function () {
				if (this.mainData["katilimci2Id"]) {
					return { "Value": this.mainData["katilimci2Id"], "Name": this.mainData["katilimci2Name"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["katilimci2Id"] = null;
					this.mainData["katilimci2Name"] = null;
				}
				else {
					this.mainData["katilimci2Id"] = newValue.Value;
					this.mainData["katilimci2Name"] = newValue.Name;
				}
			}
		},
		katilimci3Selected: {
			get: function () {
				if (this.mainData["katilimci3Id"]) {
					return { "Value": this.mainData["katilimci3Id"], "Name": this.mainData["katilimci3Name"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["katilimci3Id"] = null;
					this.mainData["katilimci3Name"] = null;
				}
				else {
					this.mainData["katilimci3Id"] = newValue.Value;
					this.mainData["katilimci3Name"] = newValue.Name;
				}
			}
		},
		katilimci4Selected: {
			get: function () {
				if (this.mainData["katilimci4Id"]) {
					return { "Value": this.mainData["katilimci4Id"], "Name": this.mainData["katilimci4Name"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["katilimci4Id"] = null;
					this.mainData["katilimci4Name"] = null;
				}
				else {
					this.mainData["katilimci4Id"] = newValue.Value;
					this.mainData["katilimci4Name"] = newValue.Name;
				}
			}
		},
		katilimci5Selected: {
			get: function () {
				if (this.mainData["katilimci5Id"]) {
					return { "Value": this.mainData["katilimci5Id"], "Name": this.mainData["katilimci5Name"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["katilimci5Id"] = null;
					this.mainData["katilimci5Name"] = null;
				}
				else {
					this.mainData["katilimci5Id"] = newValue.Value;
					this.mainData["katilimci5Name"] = newValue.Name;
				}
			}
		},
		satisProjesiSelected: {
			get: function () {
				if (this.mainData["satisProjesiId"]) {
					return { "Value": this.mainData["satisProjesiId"], "Name": this.mainData["satisProjesiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["satisProjesiId"] = null;
					this.mainData["satisProjesiName"] = null;
				}
				else {
					this.mainData["satisProjesiId"] = newValue.Value;
					this.mainData["satisProjesiName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnLoad(){
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.entityId != null) {
				try {
					debugger;
					let data = await this.crmService.getRandevuById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}

					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];
						this.mainData["entityId"] = this.entityId;

						if (this.mainData["ilgiId"]) {
							this.$refs.entity_ilgi.setDefaultData({ "Value": this.mainData["ilgiId"], "Name": this.mainData["ilgiName"] });
							this.satisProjesi_CrmDataTable_options.filterParent[0].value = this.mainData["ilgiId"];
						}

						if (this.mainData["musteriKatilimci1Id"]) {
							this.$refs.entity_musteriKatilimci1.setDefaultData({ "Value": this.mainData["musteriKatilimci1Id"], "Name": this.mainData["musteriKatilimci1Name"] });
						}

						if (this.mainData["musteriKatilimci2Id"]) {
							this.$refs.entity_musteriKatilimci2.setDefaultData({ "Value": this.mainData["musteriKatilimci2Id"], "Name": this.mainData["musteriKatilimci2Name"] });
						}

						if (this.mainData["musteriKatilimci3Id"]) {
							this.$refs.entity_musteriKatilimci3.setDefaultData({ "Value": this.mainData["musteriKatilimci3Id"], "Name": this.mainData["musteriKatilimci3Name"] });
						}

						if (this.mainData["musteriKatilimci4Id"]) {
							this.$refs.entity_musteriKatilimci4.setDefaultData({ "Value": this.mainData["musteriKatilimci4Id"], "Name": this.mainData["musteriKatilimci4Name"] });
						}

						if (this.mainData["musteriKatilimci5Id"]) {
							this.$refs.entity_musteriKatilimci5.setDefaultData({ "Value": this.mainData["musteriKatilimci5Id"], "Name": this.mainData["musteriKatilimci5Name"] });
						}

						if (this.mainData["katilimci1Id"]) {
							this.$refs.entity_katilimci1.setDefaultData({ "Value": this.mainData["katilimci1Id"], "Name": this.mainData["katilimci1Name"] });
						}

						if (this.mainData["katilimci2Id"]) {
							this.$refs.entity_katilimci2.setDefaultData({ "Value": this.mainData["katilimci2Id"], "Name": this.mainData["katilimci2Name"] });
						}

						if (this.mainData["katilimci3Id"]) {
							this.$refs.entity_katilimci3.setDefaultData({ "Value": this.mainData["katilimci3Id"], "Name": this.mainData["katilimci3Name"] });
						}

						if (this.mainData["katilimci4Id"]) {
							this.$refs.entity_katilimci4.setDefaultData({ "Value": this.mainData["katilimci4Id"], "Name": this.mainData["katilimci4Name"] });
						}

						if (this.mainData["katilimci5Id"]) {
							this.$refs.entity_katilimci5.setDefaultData({ "Value": this.mainData["katilimci5Id"], "Name": this.mainData["katilimci5Name"] });
						}

						if (this.mainData["satisProjesiId"]) {
							this.$refs.entity_satisProjesi.setDefaultData({ "Value": this.mainData["satisProjesiId"], "Name": this.mainData["satisProjesiName"] });
						}

						if (this.mainData["baslangicSaati"]) {
							this.mainData["baslangicSaati"] = new Date(this.mainData.baslangicSaati);
						}

						if (this.mainData["bitisSaati"]) {
							this.mainData["bitisSaati"] = new Date(this.mainData.bitisSaati);
						}
					}
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				loader.hide();
			}
		},
		async OnSave(kapat) {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getRandevuUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnDelete(){
            this.deleteItem('appointment', this.entityId);
        },
		async OnTamamlandiOlarakIsaretle(){
			try {
				const response = await this.crmService.getRandevuTamamlandiOlarakIsaretle(this.entityId);
				debugger;
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Randevu Kapatılamadı', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Randevu Başarıyla tamamlandı', life: 3000});

						if (window.opener) {
							window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
						}
						
						setTimeout(() => {
							this.OnLoad();
						}, 500);
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
			}
		},
		async RandevuyuYenidenEtkinlestir() {
			debugger;
			this.$confirm.require({
                message: 'Randevuyu ETKİNLEŞTİRMEK istediğinize emin misiniz?',
                header: 'ONAY',
                icon: 'pi pi-times-circle',
                accept: async () => {
                    let loader = this.$loading.show({
                        container: this.$refs.smsContainer
                    });

                    try {
                        const postData = {
                            logicalName: 'appointment',
                            entiyId: this.entityId,
                            stateCode: 0,
                            statusCode: 1
                        };

                        const response = await this.crmService.ChangeStateStatus(postData);
                        debugger;
                        if (response) {
                            if (response.hata == true) {
                                this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
                            }else {
                                this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Durum Başarıyla Değiştirildi', life: 3000});
                                setTimeout(() => {
                                    this.OnLoad();
                                }, 1000);
                            }
                        }
                    } catch (error) {
                        this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu', detail: error.message, life: 3500});
                    }
                    finally {
                        loader.hide();
                    }
                },
            });
		},
		changeSure(event){
            debugger;
            console.log(event.value);

			var minute = this.mainData.baslangicSaati.getMinutes();
			var hour = this.mainData.baslangicSaati.getHours();
			var month = this.mainData.baslangicSaati.getUTCMonth();
			var day = this.mainData.baslangicSaati.getUTCDate();
			var year = this.mainData.baslangicSaati.getUTCFullYear();
			
			let bitisTarihi = new Date(year, month, day, hour, minute);
			let eklenecekDakika = this.mainData.baslangicSaati.getMinutes() + event.value;
			bitisTarihi.setMinutes(eklenecekDakika);

			this.mainData.bitisSaati = bitisTarihi;
        },
		isKaydetVisible(){
			if (this.isDisabled) {
				return false;
			}
			else
			{
				return this.mainData.durum == 0
			}
		},
		isTamamlandiOlarakIsaretleVisible() {
			return this.isDisabled == false;
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	validations() {
		return {
			mainData: {
				ilgiName: {
					required : helpers.withMessage('İlgi alanı dolu olmalıdır!', required),
				},
				grupFirmasi: {
					required : helpers.withMessage('Grup Firması alanı dolu olmalıdır!', required),
				},
				katilimci1Name: {
					required : helpers.withMessage('Form Katılımcı 1 alanı dolu olmalıdır!', required),
				},
				musteriKatilimci1Name: {
					required : helpers.withMessage('Müşteri Katılımcı 1 alanı dolu olmalıdır!', required),
				},
				konu: {
					required : helpers.withMessage('Konu alanı dolu olmalıdır!', required),
				},
				aciklama: {
					required : helpers.withMessage('Açıklama alanı dolu olmalıdır!', required),
				},
				baslangicSaati: {
					required : helpers.withMessage('Başlangıç Tarihi alanı dolu olmalıdır!', required),
				},
				bitisSaati: {
					required : helpers.withMessage('Bitiş Tarihi alanı dolu olmalıdır!', required),
				},
				tezgah: {
					required : helpers.withMessage('Tezgah alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
